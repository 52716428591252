@use "sass:math";

// Variables
html {
  --apt-primary: #4157FF;
  --apt-success-1: #10C2A6;
  --apt-warning-1: #FF9D00;
  --apt-action: #FFA10B;
  --apt-error-1: #EA394C;
  --apt-black: #000000;
  --apt-white: #FFFFFF;
  --apt-grey-1: #262626;
  --apt-grey-2: #4C4C4B;
  --apt-grey-3: #989896;
  --apt-grey-4: #B2B2B0;
  --apt-grey-5: #CBCBC9;
  --apt-grey-6: #DBDBD8;
  --apt-grey-7: #EAEAE7;
  --apt-grey-8: #F5F5F2;
  --apt-grey-9: #FAFAF9;
  --primary: #4157FF;
  --apt-blue-1: #4279FF;
  --registry-plate-yellow: #FFC81F;
  --transparent: rgba(0, 0, 0, 0);

  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;

  --blue-gradient: linear-gradient(180deg, #4157ff, #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);
  --blue-gradient-x: linear-gradient(90deg, #01cef4, #00c7f9, #00bfff, #00b6ff, #00aeff, #00a4ff, #009aff, #0090ff, #0084ff, #0077ff, #0068ff, #4157ff);
  --black-gradient: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  --shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  --shadow-b: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0px 4px 10px 0px rgba(0, 0, 0, 0.1), 0px 10px 15px 2px rgba(0, 0, 0, 0.1);
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);

  --fellix: "Fellix", "Helvetica Neue", "Verdana", sans-serif;
  --fa: "Font Awesome 6 Pro";

  --0px: 0rem;

  @for $i from 1 through 100 {
    --#{$i}px: #{math.div(round(math.div($i, 18) * 1000), 1000)}rem;
  }

  --104px: 5.778rem;
  --116px: 6.445rem;
}

body {
  font-family: "Fellix", "Helvetica Neue", "Verdana", sans-serif;
}